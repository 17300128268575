<template>
  <div>
    <div v-show="myAccountDisplay">
      <div class="apply_box_top">
        <div class="apply_box_top_left">
          <div class="apply_box_total">
            <div class="apply_box_total_icon">
              <i class="iconfont icon-walletY-fill"></i>
            </div>
            <div class="apply_box_total_text">
              <span>贷款总金额(元)</span>
              <el-popover placement="top" trigger="hover" :content="totalAccount | NumFormat" popper-class="popper_class_account">
                <div slot="reference" class="text_overflow1">{{totalAccount | NumFormat}}</div>
              </el-popover>
            </div>
          </div>
        </div>
        <div class="apply_box_top_right">
          <div ref="echarts1" class="echarts_box"></div>
        </div>
      </div>
      <div class="table_common">
        <div class="search_flex_box">
          <div class="search_condition">
            <el-form ref="financingForm" :model="financingForm">
              <el-form-item label="融资编号:" class="financing_number financing_border" prop="financingNum">
                <el-input v-model="financingForm.financingNum" @input="searchInput" maxlength="20">
                  <template slot="prepend">FN</template>
                </el-input>
              </el-form-item>
              <el-form-item label="资方名称:" prop="manageName">
                <el-input v-model="financingForm.manageName"></el-input>
              </el-form-item>
              <el-form-item label="融资期限:" prop="loanTerm">
                <el-input v-model="financingForm.loanTerm" @input="searchDay" maxlength="4"></el-input>
              </el-form-item>
              <el-form-item label="账款状态:" prop="status">
                <el-select v-model="financingForm.status" placeholder="" class="select_class">
                  <el-option v-for="item in optionsState" :key="item.codeState" :label="item.value" :value="item.codeState">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="search_boxs flex_center_between_box">
            <el-tooltip popper-class="search" effect="light" content="搜索" placement="top">
              <div class="search_button_box" @click="searchForm()">
                <i class="iconfont icon-10sousuo"></i>
              </div>
            </el-tooltip>
            <el-tooltip popper-class="search" effect="light" content="重置" placement="top">
              <div class="reset_button_box" @click="cleargetDate()">
                <i class="iconfont icon-shuaxin"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="table_box">
          <div class="table_box_top flex_center">
            <div class="table_box_top_title">我的还款</div>
            <div class="flex_center_end_box">
              <div class="search_button button data_export">数据导出</div>
            </div>
          </div>
          <div class="table_box_bottom">
            <el-table ref="treeTable" :data="tableData" stripe border style="width: 100%" :key="tableKey">
              <el-table-column type="expand" align="center" width="1" class-name="border_change">
                <template slot-scope="scope">
                  <div class="tree_table_box">
                    <el-table :data="scope.row.children" stripe border :key="onClickExpansionIndexkey" style="width: 100%">
                      <el-table-column label="发票号" prop="invoiceNo" align="center" show-overflow-tooltip :resizable="false" />
                      <el-table-column label="采购商名称" prop="purchaseName" align="left" header-align="center" show-overflow-tooltip :resizable="false" :width="nameWidth" />
                      <el-table-column label="发票日期" sortable prop="invoiceDate" align="center" show-overflow-tooltip :resizable="false" />
                      <el-table-column label="发票金额(元)" sortable prop="invoiceAmount" align="right" header-align="center" show-overflow-tooltip :resizable="false">
                        <template slot-scope="scope">
                          {{scope.row.invoiceAmount | NumFormat}}
                        </template>
                      </el-table-column>
                      <el-table-column label="发票评级" prop="invoiceGrade" align="center" show-overflow-tooltip :resizable="false">
                        <template slot-scope="scope">
                          <div class="link" @click="clickInvoiceGrade(scope.row.invoiceGrade)">{{scope.row.invoiceGrade}}</div>
                        </template>
                      </el-table-column>
                      <el-table-column label="发票账期(天)" sortable prop="accPeriod" align="center" show-overflow-tooltip :resizable="false" />
                    </el-table>
                    <Page :totalFont=true :total=childrenpage.total :currentPage='childrenpage.pageNo' @onPageChange="onPageChangeList"></Page>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="融资编号" prop="financingNum" align="center" show-overflow-tooltip :resizable="false" class-name="financing_cursor" :width="width190">
                <template slot-scope="scope">
                  <span class="expans_icon" :class="{'expandIconExpanded':scope.row.isExpand}" @click="onClickExpansion(scope.row)"><i class="iconfont icon-youjiantou"></i>
                    {{scope.row.financingNum}}</span>
                </template>
              </el-table-column>
              <el-table-column label="资方名称" prop="manageName" align="left" header-align="center" show-overflow-tooltip :resizable="false" :width="nameWidth">
                <template slot-scope="scope">
                  <!-- <a href="#" class="link" @click="detailIndex()">{{scope.row.manageName}}</a> -->
                  {{scope.row.manageName}}
                </template>
              </el-table-column>
              <el-table-column label="融资发票数量" sortable prop="financingInvoiceNum" show-overflow-tooltip :width="width148" align="center" :resizable="false" />
              <el-table-column label="融资金额(元)" sortable prop="financingAmount" align="right" header-align="center" show-overflow-tooltip :width="width138" :resizable="false">
                <template slot-scope="scope">
                  {{scope.row.financingAmount | NumFormat}}
                </template>
              </el-table-column>
              <el-table-column label="账款状态" prop="status" align="center" show-overflow-tooltip :resizable="false" :width="width120">
                <template slot-scope="scope">
                  <div v-if="scope.row.status== '4'">未还款</div>
                  <div v-else-if="scope.row.status== '5'&&scope.row.highly == '1'">已还款</div>
                  <div v-else-if="scope.row.status== '5'">已还款</div>
                  <div v-else-if="scope.row.status== '11'">等待确认还款</div>
                  <div v-else-if="scope.row.status== '12'&&scope.row.highly == '1'">已收款</div>
                  <div v-else-if="scope.row.status== '12'">已收款</div>
                  <div v-else-if="scope.row.highly == '1'" class="be_overdue">逾期</div>
                  <div v-else class="be_overdue">逾期</div>
                </template>
              </el-table-column>
              <el-table-column label="融资利率" sortable prop="loanRate" align="right" header-align="center" show-overflow-tooltip :resizable="false" />
              <el-table-column label="融资期限(天)" sortable prop="loanTerm" align="center" show-overflow-tooltip :width="width138" :resizable="false" />
              <el-table-column label="操 作" prop="operation" align="center" :resizable="false" fixed="right" :width="operationWidth">
                <template slot-scope="scope">
                  <div class="table_oper flex_center">
                    <el-tooltip v-if="scope.row.status== '5' || scope.row.status== '11'" class="item_color_money" effect="light" content="还款详情" placement="top">
                      <i class="iconfont icon-jieqinghuankuan" @click="accountDetail(scope.row)"></i>
                    </el-tooltip>

                    <el-tooltip class="item_color_money"  v-if="scope.row.status== '4' || scope.row.status== '6'" effect="light" content="还款" placement="top">
                      <i class="iconfont icon-xinyongkahuankuan" @click="chooseFinancingButton(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip class="item_color" effect="light" content="融资详情" placement="top">
                      <i class="iconfont icon-shuxingliebiaoxiangqing" @click="detailDialog(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip class="item_color" effect="light" content="服务" placement="top">
                      <i class="iconfont icon--fuwu-xianxing"></i>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="" prop="" align="center" show-overflow-tooltip width=30 class-name="add_padding" :resizable="false" fixed="right">
                <template slot="header">
                  <div class="table_add">
                    <i class="iconfont icon-jiahao"></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <Page :totalFont=true :total=page.total :currentPage='page.pageNo' @onPageChange="onPageChange"></Page>
        </div>
      </div>
    </div>
    <!-- 发票评级弹窗 -->
    <el-dialog title="发票评级" :visible.sync="invoiceGradeDialog" center class="dialog_1268" :close-on-click-modal="false">
      <img :src="invoiceAImg" v-if="invoiceImg == 1">
      <img :src="invoiceBImg" v-else-if="invoiceImg == 2">
      <img :src="invoiceCImg" v-else-if="invoiceImg == 3">
      <img :src="invoiceDImg" v-else-if="invoiceImg == 4">
      <img :src="invoiceNaImg" v-else>
    </el-dialog>
    <!-- 还款详情弹框 -->
    <el-dialog title="还款详情" :visible.sync="repaymentDetailDialog" center class="adds dialog_width500" :close-on-click-modal="false">
      <div class="waiting_signing" v-if="status == '11'">等待确认还款</div>
      <div class="waiting_signing" v-else-if="status == '4'">未还款</div>
      <div class="waiting_signing" v-else-if="status == '5'">已还款</div>
      <div class="waiting_signing" v-else-if="status == '12'">已收款</div>
      <div class="waiting_signing" v-else>逾期</div>
      <div class="add_invoice_message inner_common notXin add_invoice_messagebt">
        <el-table class="dialog_table" :data="RepaymentDetails" stripe border style="width: 100%">
          <el-table-column class="text_overflow" label="还款金额" prop="repayAmount" align="center" :resizable="false" show-overflow-tooltip :width="dialogidWidth">
            <template slot-scope="scope">
              {{scope.row.repayAmount | NumFormat}}
            </template>
          </el-table-column>
          <el-table-column class="text_overflow" label="还款时间" prop="repayTime" align="center" show-overflow-tooltip :resizable="false" />
          <el-table-column class="text_overflow" label="还款银行" prop="repayBank" align="center" header-align="center" show-overflow-tooltip :resizable="false">
          </el-table-column>
          <el-table-column class="text_overflow" label="还款账号" prop="repayAccount" align="center" show-overflow-tooltip :resizable="false" />
          <el-table-column class="text_overflow" label="银行流水号" prop="repaySerialNum" align="center" header-align="center" show-overflow-tooltip :resizable="false" :width="dialogWidth" />
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button class="footer_button2" round @click="repaymentDetailDialog = false">关 闭</el-button> -->
        <el-button class="footer_button1" type="primary" @click="confirmPreference()">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 融资详情弹框 -->
    <el-dialog title="融资详情" :visible.sync="repaymentConfirmationDetailDialog" center class="adds dialog_width500" :close-on-click-modal="false">
      <div class="waiting_signing" v-if="status == '11'">等待确认还款</div>
      <div class="waiting_signing" v-else-if="status == '4'">未还款</div>
      <div class="waiting_signing" v-else-if="status == '5'">已还款</div>
      <div class="waiting_signing" v-else-if="status == '12'">已收款</div>
      <div class="waiting_signing" v-else>逾期</div>
      <div class="add_invoice_message ">
        <el-form :model="repaymentFormDis">
          <el-form-item label="融资编号:">
            <el-input v-model="repaymentFormDis.financingNum" disabled></el-input>
          </el-form-item>
          <el-form-item label="资方名称:">
            <el-input v-model="repaymentFormDis.managementName" disabled></el-input>
          </el-form-item>
          <el-form-item label="融资金额:">
            <el-input v-model="repaymentFormDis.managementFinancingAmount" disabled></el-input>
          </el-form-item>
          <el-form-item label="融资利率:">
            <el-input v-model="repaymentFormDis.managementRate" disabled></el-input>
          </el-form-item>
          <el-form-item label="融资期限:">
            <el-input v-model="repaymentFormDis.loanTerm" disabled></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button1" type="primary" @click="repaymentConfirmationDetailDialog = false;fileList=[]">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 还款确认弹框 -->
    <el-dialog title="还款" :visible.sync="repaymentConfirmationDialog" center class="inner_common_dialog " :class="accountShow ? 'detail_widthPay1000' : 'dialog_width590'" :close-on-click-modal="false"
      :before-close="handleClose">
      <div class="waiting_signing" v-if="repaymentForm.status == '4'">未还款</div>
      <div class="waiting_signing" v-else>逾期</div>
      <!-- <div class="first_account add_invoice2" v-show="!accountShow">
        <el-form :model="repaymentForm" :rules="repaymentRules" ref="repaymentForm">
          <el-form-item label="剩余还款金额:">
            <el-input v-model="repaymentForm.surplusRepaymentAmount" disabled></el-input>
          </el-form-item>
          <el-form-item label="还款金额:" prop="repaymentAmount">
            <el-input v-model="repaymentForm.repaymentAmount" :disabled="repaymentAmountFlag" maxlength="8"></el-input>
          </el-form-item>
          <el-form-item label="还款时间:" prop="repaymentTime" :class="exceptRepaymentClass ? 'exceptRepaymentClass' : ''">
            <el-date-picker v-model="repaymentForm.repaymentTime" prefix-icon="iconfont icon-riqi" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="applyDateChoose"
              @change="exceptArrivalChange()" :editable="false">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="还款银行:" prop="repaymentBank">
            <el-input v-model="repaymentForm.repaymentBank" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="还款账号:" prop="repaymentAccount">
            <el-input v-model="repaymentForm.repaymentAccount" maxlength="24"></el-input>
          </el-form-item>
          <el-form-item label="银行流水号:" prop="supplyBankSerialNumber">
            <el-input v-model="repaymentForm.supplyBankSerialNumber" maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="还款来源:" prop="repaymentSource">
            <el-select v-model="repaymentForm.repaymentSource" placeholder="">
              <el-option v-for="item in repaymentSourceSelect" :value="item.codeState" :label="item.value" :key="item.codeState"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="还款来源内容:" prop="repaymentSourceContent" v-show="repaymentForm.repaymentSource == '1'">
            <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6}" maxlength="200" show-word-limit v-model="repaymentForm.repaymentSourceContent">
            </el-input>
          </el-form-item>
          <el-form-item label="上传还款凭证:" class="change_flex payForm1" prop="upload">
            <el-upload ref="uploadForm" :http-request="handleUploadFile" v-model="file" class="upload-demo" action="fakeaction" list-type="picture" accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PBG">
              <div class="upload_box">
                <i class="el-icon-plus"></i>
              </div>
              <span slot="tip" class="el-upload__tip">支持JPG、PNG格式，大小不超过15M</span>
            </el-upload>
          </el-form-item>
          <div v-show="file" class="invoiceUrlClass">
            <ul>
              <li v-for="(item,index) in file" :key="index" class="flex_center" style="position:relative">
                <img :src="jpg + item">
                <div class="close_icon" @click="deleteUpload(index)">
                  <i class="iconfont icon-yuyinguanbi"></i>
                </div>
              </li>
            </ul>
          </div>
        </el-form>
      </div> -->
      <div class="inner_common add_invoice_messages">
        <el-form :model="repaymentForm" :rules="repaymentRules" ref="repaymentForm">
          <el-row gutter="20">
            <el-col span="12" v-show="accountShow">
              <div class="bg-purple-light3">
                <el-table :data="RepaymentDetails" stripe border style="width: 100%">
                  <el-table-column label="还款金额" sortable prop="repayAmount" show-overflow-tooltip align="center" :resizable="false">
                    <template slot-scope="scope">
                      {{scope.row.repayAmount | NumFormat}}
                    </template>
                  </el-table-column>
                  <el-table-column label="还款时间" sortable prop="repayTime" show-overflow-tooltip align="center" :resizable="false" />
                  <el-table-column label="还款银行" sortable prop="repayBank" show-overflow-tooltip align="center" :resizable="false" />
                  <el-table-column label="还款账号" sortable prop="repayAccount" show-overflow-tooltip align="center" :resizable="false" />
                  <el-table-column label="银行流水号" sortable prop="repaySerialNum" show-overflow-tooltip align="center" :resizable="false" type="number" />
                </el-table>
                <!-- 骆 删除 开始 -->
                <!-- <el-form-item label="剩余还款金额:" class="margin_delete">
                  <el-input v-model="repaymentForm.surplusRepaymentAmount" disabled>
                  </el-input>
                </el-form-item>
                <el-form-item label="还款金额:" prop="repaymentAmount" class="margin_delete label_width_light3">
                  <el-input v-model="repaymentForm.repaymentAmount" :disabled="repaymentAmountFlag" @focus="inputPayMoneyFocusfin(repaymentForm.repaymentAmount)"
                    @blur="inputPayMoneyBlurfin(repaymentForm.repaymentAmount)"></el-input>
                </el-form-item> -->
                <!-- 骆 删除 结束 -->
              </div>
            </el-col>
            <el-col span="12">
              <div class="bg-purple-light4" :class="accountShow ? 'bg-purple-light4' : 'bg-purple-light4 bg-purple-nobg'">
                <!-- 骆 添加 开始 -->
                <el-form-item v-show="accountShow" label="剩余还款金额:">
                  <el-input v-model="repaymentForm.surplusRepaymentAmount" disabled></el-input>
                </el-form-item>
                <el-form-item v-show="accountShow" label="还款金额:" prop="repaymentAmount">
                  <el-input v-model="repaymentForm.repaymentAmount" :disabled="repaymentAmountFlag" @focus="inputPayMoneyFocusfin(repaymentForm.repaymentAmount)"
                    @blur="inputPayMoneyBlurfin(repaymentForm.repaymentAmount)"></el-input>
                </el-form-item>
                <!-- 骆 添加 结束 -->
                <el-form-item label="剩余还款金额:" v-show="!accountShow">
                  <el-input v-model="repaymentForm.surplusRepaymentAmount" disabled></el-input>
                </el-form-item>
                <el-form-item label="还款金额:" :class="repaymentAmountFlag ? 'RepaymentStart' : ''" prop="repaymentAmount" v-show="!accountShow">
                  <el-input v-model="repaymentForm.repaymentAmount" :disabled="repaymentAmountFlag" @focus="inputPayMoneyFocusfin(repaymentForm.repaymentAmount)"
                    @blur="inputPayMoneyBlurfin(repaymentForm.repaymentAmount)"></el-input>
                </el-form-item>
                <el-form-item label="还款时间:" prop="repaymentTime" class="payTime" :class="exceptRepaymentClass ? 'exceptRepaymentClass' : ''">
                  <el-date-picker v-model="repaymentForm.repaymentTime" prefix-icon="iconfont icon-riqi" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="applyDateChoose"
                    @change="exceptArrivalChange()" :editable="false">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="还款银行:" prop="repaymentBank">
                  <el-input v-model="repaymentForm.repaymentBank" maxlength="20"></el-input>
                </el-form-item>
                <el-form-item label="还款账号:" prop="repaymentAccount">
                  <el-input v-model="repaymentForm.repaymentAccount" maxlength="24"></el-input>
                </el-form-item>
                <el-form-item label="银行流水号:" prop="supplyBankSerialNumber">
                  <el-input v-model="repaymentForm.supplyBankSerialNumber" maxlength="30" @input="regNumber1"></el-input>
                </el-form-item>
                <el-form-item label="还款来源:" prop="repaymentSource" class="payForm1">
                  <el-select v-model="repaymentForm.repaymentSource" placeholder="">
                    <el-option v-for="item in repaymentSourceSelect" :value="item.codeState" :label="item.value" :key="item.codeState"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="还款来源内容:" prop="repaymentSourceContent" v-show="repaymentForm.repaymentSource == '1'">
                  <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6}" maxlength="200" show-word-limit v-model="repaymentForm.repaymentSourceContent">
                  </el-input>
                </el-form-item>
                <el-form-item label="上传还款凭证:" class="change_flex payForm1" prop="upload">
                  <el-upload ref="uploadForm" :http-request="handleUploadFile" v-model="file" class="upload-demo" action="fakeaction" list-type="picture" accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PBG"
                    limit="1">
                    <div class="upload_box">
                      <i class="el-icon-plus"></i>
                    </div>
                    <span slot="tip" class="el-upload__tip">支持JPG、PNG格式，大小不超过15M</span>
                  </el-upload>
                </el-form-item>
                <div v-show="file" class="invoiceUrlClass">
                  <ul>
                    <li v-for="(item,index) in file" :key="index" class="flex_center" style="position:relative">
                      <img :src="jpg + item">
                      <div class="close_icon" @click="deleteUpload(index)">
                        <i class="iconfont icon-yuyinguanbi"></i>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="cancelRepaymentConfirmation()">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="submitRepaymentConfirmation()">提 交</el-button>
      </span>
    </el-dialog>
    <div v-show="detailDisplay">
      <div class="second_return_icon" @click="returnButton()">
        <i class="iconfont icon-fanhui"></i>
        融资企业详情
      </div>
      <Company></Company>
    </div>
  </div>
</template>
<script>
import { findCodeInfoByKey, getSupplierLoanList, getInvoiceList, uploadSupplierLoan, supplierLoanState, showLoan, uploads, getFinancingComparisonById, getRepaymentDetails } from "@/api/supplier.js";
import { mixins1 } from "@/mixins/index";
import Page from '@/components/page'
import Company from '../../supplier/components/companyDetail.vue'
export default {
  mixins: [mixins1],
  components: {
    Page,
    Company
  },
  data() {
    const valErrorrepaymentAmount = (rule, val, cb) => {
      if (!val) return cb(new Error('请输入还款金额'))
      // if (!Number(val)) return cb(new Error('请输入数字值'))
      // let reg = /^(0|[1-9]\d*)(\s|$|\.\d{1,2}\b)/ //小数限制0-2位
      // let reg = /^0\.[0-9]{1,2}$|^0$|^[1-9]\d{0,7}\.\d{1,2}$|^[1-9]\d{0,7}$/;
      let reg = /^([0-9]+|[0-9]{1,3}(,[0-9]{3})*)(.[0-9]{1,2})?$/
      if (!reg.test(val)) return cb(new Error('请输入正确价格'));
      if(val === 0 || val === '0'){
        return cb(new Error('还款金额不能为0'))
      }
      if (Number(this.filterOut(val)) > Number(this.filterOut(this.repaymentForm.surplusRepaymentAmount))) {
        return cb(new Error('您的还款金额不能大于剩余还款金额'))
      }
      // if (this.filterOut(val) < this.filterOut(this.repaymentForm.surplusRepaymentAmount)) return cb(new Error('您的还款金额不能大于剩余还款金额'));
      return cb();
    }
    const valErrorrepaymentTime = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请选择还款时间'))
      }
      return cb()
    }
    const valErrorrepaymentBank = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入还款银行'))
      } else if (/^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(val) == false) {
        return cb(new Error("请输入20位中英文或数字"));
      }
      return cb()
    }
    const valErrorrepaymentAccountNumber = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入还款账号'))
      } else if (/^[0-9]*$/.test(val) == false) {
        return cb(new Error("请输入24位数字"));
      }
      return cb()
    }
    const valErrorbankSerialNumber = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入银行流水号'))
      } else if (/^[0-9]*$/.test(val) == false) {
        return cb(new Error("请输入30位数字"));
      }
      return cb()
    }
    const valErrorrepaymentSource = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请选择还款来源'))
      }
      return cb()
    }
    const valErrorrepaymentSourceContent = (rule, val, cb) => {
      if (!val && (this.repaymentForm.repaymentSource == '1')) {
        return cb(new Error('请输入还款来源内容'))
      }
      return cb()
    }
    const valErrorRepayment = (rule, val, cb) => {
      if (!this.file.length) {
        return cb(new Error('请上传供应商合同'))
      }
      return cb()
    }
    return {
      repaymentAmountFlag: false,
      accountShow: true,
      status: '',
      optionsState: [],
      repaymentRules: {
        repaymentTime: [{ required: true, validator: valErrorrepaymentTime, trigger: "change" }],
        repaymentBank: [{ required: true, validator: valErrorrepaymentBank, trigger: "blur" }],
        repaymentAccount: [{ required: true, validator: valErrorrepaymentAccountNumber, trigger: "blur" }],
        supplyBankSerialNumber: [{ required: true, validator: valErrorbankSerialNumber, trigger: "blur" }],
        repaymentSource: [{ required: true, validator: valErrorrepaymentSource, trigger: "change" }],
        repaymentSourceContent: [{ required: true, validator: valErrorrepaymentSourceContent, trigger: "change" }],
        upload: [{ required: true, validator: valErrorRepayment, trigger: "blur" }],
        repaymentAmount: [{ required: true, validator: valErrorrepaymentAmount, trigger: "blur" }],
      },
      financingForm: {
        financingNum: '',
        manageName: '',
        loanTerm: '',
        status: '',
      },
      repaymentForm: {
        actualAmount: '',
        supplierId: '',
        managerId: '',
        financingInvoiceNum: '',
        financingNum: '',
        manageName: '',
        financingAmount: '',
        loanRate: '',
        loanTerm: '',
        repaymentAmount: '',
        repaymentTime: '',
        repaymentBank: '',
        repaymentAccount: '',
        supplyBankSerialNumber: '',
        repaymentSource: '0',
        supplyRepaymentVoucher: [],
        repaymentSourceContent: '',
        surplusRepaymentAmount: ''
      },
      repaymentFormDis: {
        supplierId: '',
        managerId: '',
        financingInvoiceNum: '',
        financingNum: '',
        manageName: '',
        financingAmount: '',
        loanRate: '',
        loanTerm: '',
        repaymentAmount: '',
        repaymentTime: '',
        repaymentBank: '',
        repaymentAccount: '',
        supplyBankSerialNumber: '',
        repaymentSource: '0',
        supplyRepaymentVoucher: [],
        repaymentSourceContent: '',
      },
      tableKey: 0,
      id: '',
      tableData: [],
      listQuery: {
        total: 0
      },
      list1: [
        {
          value: 0,
          name: '已还款金额'
        }, {
          value: 0,
          name: '未还款金额'
        }, {
          value: 0,
          name: '逾期金额'
        },
      ],
      fileList: [
      ],
      noButton: true,
      colorChange: true,
      repaymentConfirmationDialog: false,
      repaymentConfirmationDetailDialog: false,
      repaymentDetailDialog: false,
      totalAccount: 0,
      myAccountDisplay: true,
      detailDisplay: false,
      page: {
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
      childrenpage: {
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
      onClickExpansionFinancingNum: '',
      onClickExpansionIndex: '',
      onClickExpansionIndexkey: 1,
      file: [],
      repaymentSourceSelect: [
      ],
      exceptRepaymentClass: false,
      percentStrs: '',
      RepaymentDetails: [],
      dialogidWidth: 94,
      dialogWidth: 94,
      invoiceAImg: require('@/assets/img/system/invoice_A.png'),
      invoiceBImg: require('@/assets/img/system/invoice_B.png'),
      invoiceCImg: require('@/assets/img/system/invoice_C.png'),
      invoiceDImg: require('@/assets/img/system/invoice_D.png'),
      invoiceNaImg: require('@/assets/img/system/invoice_NA.png'),
      invoiceGradeDialog: false,
      invoiceImg: ''
    }
  },
  created() {
    this.getSelect()
    this.resizeFn();
    this.tableWidth();
    this.getDate()
  },
  methods: {
    // 发票评级提示
    clickInvoiceGrade(val) {
      this.invoiceGradeDialog = true
      if (val == 'A') {
        this.invoiceImg = 1
      } else if (val == 'B') {
        this.invoiceImg = 2
      } else if (val == 'C') {
        this.invoiceImg = 3
      } else if (val == 'D') {
        this.invoiceImg = 4
      } else {
        this.invoiceImg = 5
      }
    },
    searchInput(val) {
      val = val.replace(/[^\d]/g, '')
      this.financingForm.financingNum = val
    },
    searchDay(val) {
      val = val.replace(/[^\d]/g, '')
      this.financingForm.loanTerm = val
    },
    regNumber1(val) {
      val = val.replace(/[^\d]/g, '')
      this.repaymentForm.supplyBankSerialNumber = val
    },
    inputPayMoneyFocusfin(value) {
      this.repaymentForm.repaymentAmount = this.filterOut(value)
    },
    inputPayMoneyBlurfin(value) {
      console.log(value)
      if(value !='' && value!=null && value !=undefined){
        this.repaymentForm.repaymentAmount = this.filter(value)
      }
      
    },
    exceptArrivalChange() {
      if (this.repaymentForm.repaymentTime) {
        this.exceptRepaymentClass = true
      } else {
        this.exceptRepaymentClass = false
      }
    },
    handleUploadFile(params) {
      const file = params.file
      let form = new FormData()
      console.log(form)
      form.append("files", file)
      uploads(form).then(res => {
        this.file.push(res[0])
      })
    },
    deleteUpload(index) {
      this.file.splice(index, 1)
    },
    async onPageChangeList(val) {
      this.childrenpage.pageNo = val
      let data = await getInvoiceList('pageNo=' + this.childrenpage.pageNo + '&pageSize=' + this.childrenpage.pageSize + '&financingNum=' + this.onClickExpansionFinancingNum)
      this.childrenpage.total = data.data.total
      this.tableData[this.onClickExpansionIndex].children = data.data.records
      this.onClickExpansionIndexkey++
    },
    async getDate() {
      let data = await getSupplierLoanList('pageNo=' + this.page.pageNo + '&pageSize=' + this.page.pageSize +
        '&financingNum=' + this.financingForm.financingNum + '&manageName=' + this.financingForm.manageName + '&loanTerm=' + this.financingForm.loanTerm + '&status=' + this.financingForm.status)
      this.tableData = data.data.records
      this.page.total = data.data.total
      data = await supplierLoanState()
      this.list1[0].value = data.data.repayment
      this.list1[1].value = data.data.unrepayment
      this.list1[2].value = data.data.overdue
      this.totalAccount = data.data.repayment + data.data.unrepayment + data.data.overdue
      this.$nextTick(function () {
        this.riskEchart1(
          this.fontSize,
          this.echartsWidth
        )
      })
    },
    async getSelect() {
      let data = await findCodeInfoByKey('ms=15')
      this.optionsState = data.data
      data = await findCodeInfoByKey('ms=18')
      this.repaymentSourceSelect = data.data
    },
    searchForm() {
      this.page.pageNo = 1
      this.getDate()
    },
    cleargetDate() {
      this.$refs.financingForm.resetFields()
      this.page.pageNo = 1
      this.childrenpage.pageNo = 1
      this.getDate()
    },
    // echarts图表参数
    resizeFn() {
      if (document.body.clientWidth > 1664) {
        this.fontSize = 14
        this.width190 = 180
        this.echartsWidth = 36
        this.operationWidth = 150
      } else {
        this.fontSize = 10
        this.width190 = 120
        this.echartsWidth = 24
        this.operationWidth = 100
      }
    },
    // 展开子表格
    async onClickExpansion(row) {
      this.onClickExpansionFinancingNum = row.financingNum
      let data = await getInvoiceList('pageNo=' + this.childrenpage.pageNo + '&pageSize=' + this.childrenpage.pageSize + '&financingNum=' + row.financingNum)
      this.childrenpage.total = data.data.total
      this.tableData.map((item, index) => {
        if (row.financingNum != item.financingNum) {
          item.isExpand = false;
          this.$refs.treeTable.toggleRowExpansion(item, false);
        } else {
          this.onClickExpansionIndex = index
          this.tableData[index].children = data.data.records
          item.isExpand = !item.isExpand
        }
      })
      this.$refs.treeTable.toggleRowExpansion(row)
    },
    // 还款
    async chooseFinancingButton(row) {
      console.log(row.isStage)
      if (row.isStage == 1) {//分期还款
        this.repaymentAmountFlag = false
      } else if (row.isStage == 0) {//不分期还款
        this.repaymentAmountFlag = true
      }
      this.$nextTick(() =>
        this.$refs['repaymentForm'].resetFields()
      )
      let datadetail = await getRepaymentDetails('financingNum=' + row.financingNum)
      this.repaymentConfirmationDialog = true
      console.log(row)
      this.repaymentForm.financingAmount= row.financingAmount
      this.repaymentForm.bankAccount= row.bankAccount
      this.repaymentForm.bankReceiving= row.bankReceiving
      this.repaymentForm.financingInvoiceNum= row.financingInvoiceNum
      this.repaymentForm.financingInvoiceSupplierNum= row.financingInvoiceSupplierNum
      this.repaymentForm.financingInvoiceTotalamount= row.financingInvoiceTotalamount
      this.repaymentForm.financingNum= row.financingNum
      this.repaymentForm.flag= row.flag
      this.repaymentForm.highly= row.highly
      this.repaymentForm.id= row.id
      this.repaymentForm.investmentObject= row.investmentObject
      this.repaymentForm.invoiceNo= row.invoiceNo
      this.repaymentForm.isAgree= row.isAgree
      this.repaymentForm.isExpired= row.isExpired
      this.repaymentForm.isStage= row.isStage
      this.repaymentForm.loanRate= row.loanRate
      this.repaymentForm.manageId= row.manageId
      this.repaymentForm.manageName= row.manageName
      this.repaymentForm.paymentDate= row.paymentDate
      this.repaymentForm.rate= row.rate
      this.repaymentForm.repaymentDate= row.repaymentDate
      this.repaymentForm.repaymentDay= row.repaymentDay
      this.repaymentForm.status= row.status
      this.repaymentForm.updateDate= row.updateDate
      this.repaymentForm.userId= row.userId
      // this.repaymentForm = JSON.parse(JSON.stringify(row))
      this.repaymentForm.financingAmount = this.filter(this.repaymentForm.financingAmount)
      var strs = (100 + (this.repaymentForm.loanRate)).toString()
      var regs = RegExp(/./);
      if (strs.match(regs)) {
        var percents = (strs || '').split('.')
        var nums = percents.join('')
        this.percentStrs = Number(nums.substr(0, 1) + '.' + nums.substr(1, 3))
      }
      this.repaymentForm.surplusRepaymentAmount = (this.filterOut(this.repaymentForm.financingAmount) * this.percentStrs).toFixed(2)
      this.repaymentForm.surplusRepaymentAmount = this.filter(this.repaymentForm.surplusRepaymentAmount)
      if (row.isStage == 0) {
        this.repaymentForm.repaymentAmount = this.repaymentForm.surplusRepaymentAmount
      }
      if (datadetail.data.length == 0) {
        this.accountShow = false
        this.repaymentForm.actualAmount = this.filterOut(this.repaymentForm.surplusRepaymentAmount)
      } else {
        let len = datadetail.data.length
        this.repaymentForm.surplusRepaymentAmount = datadetail.data[len - 1].chargeBalance
        this.repaymentForm.surplusRepaymentAmount = this.filter(this.repaymentForm.surplusRepaymentAmount)
        this.repaymentForm.actualAmount = datadetail.data[len - 1].chargeBalance
        this.RepaymentDetails = datadetail.data
        this.accountShow = true
      }
      this.repaymentForm.supplierId = row.userId
      this.repaymentForm.managerId = row.manageId
      this.file = row.supplyRepaymentVoucher
      this.file = []
    },
    // 详情
    async detailDialog(row) {
      this.status = row.status
      let res = await getFinancingComparisonById('financingNum=' + row.financingNum + '&supplierId=' + row.userId + '&manageId=' + row.manageId)
      this.repaymentFormDis = res.data.manageInvestment
      this.repaymentFormDis.supplyRepaymentVoucher = res.data.repaymentVoucher
      this.repaymentFormDis.loanTerm = res.data.loanTerm
      this.repaymentConfirmationDetailDialog = true
      this.repaymentFormDis.managementFinancingAmount = this.filter(this.repaymentFormDis.managementFinancingAmount)
      this.repaymentFormDis.repaymentAmount = this.filter(this.repaymentFormDis.repaymentAmount)
    },
    async accountDetail(row) {
      let datadetail = await getRepaymentDetails('financingNum=' + row.financingNum)
      this.RepaymentDetails = datadetail.data
      this.status = row.status
      this.repaymentDetailDialog = true
    },
    confirmPreference() {
      this.repaymentDetailDialog = false
    },
    cancelRepaymentConfirmation() {
      this.repaymentConfirmationDialog = false
      this.$refs.repaymentForm.clearFiles()
    },
    async submitRepaymentConfirmation() {
      console.log(this.repaymentForm)
      this.$refs.repaymentForm.validate(async (valid) => {
        if (valid) {
          console.log(this.repaymentForm);
          this.repaymentForm.supplyRepaymentVoucher = this.file
          this.repaymentForm.repaymentAmount = this.filterOut(this.repaymentForm.repaymentAmount)
          this.repaymentForm.surplusRepaymentAmount = this.filterOut(this.repaymentForm.surplusRepaymentAmount)
          let data = await uploadSupplierLoan(this.repaymentForm)
          this.repaymentConfirmationDialog = false
          this.$refs['uploadForm'].clearFiles()
          this.getDate()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleClose() {
      this.repaymentConfirmationDialog = false
      this.$refs['uploadForm'].clearFiles()
    },
    detailIndex() {
      this.myAccountDisplay = false
      this.detailDisplay = true
    },
    // 返回上一页
    returnButton() {
      this.detailDisplay = false
      this.myAccountDisplay = true
      window.scrollTo(0, 0)
    },
  }
}
</script>

<style scoped>
  ::v-deep.RepaymentStart .el-form-item__label:before {
    content: none !important;
  }
  ::v-deep.dialog_width590 .bg-purple-nobg {
    background-color: #fff;
    height: auto !important;
  }
  ::v-deep.dialog_width590 .el-dialog {
    width: 560px;
    height: auto !important;
  }
  ::v-deep .dialog_width590 .el-dialog--center .el-dialog__body {
    padding-left: 15px;
    padding-right: 15px;
    max-height: inherit !important;
  }
  ::v-deep .dialog_width590 .el-dialog__footer {
    padding-bottom: 30px;
    text-align: center !important;
  }
  ::v-deep.dialog_width590 .el-form-item__content {
    display: flex;
  }
  ::v-deep.dialog_width590 .el-form-item {
    /* padding-top: 15px; */
    margin-left: 20px;
    margin-right: 20px;
  }

  ::v-deep .dialog_width590 .margin_delete {
    margin-left: 0;
    margin-right: 0;
  }
  ::v-deep.dialog_width590 .el-dialog--center .el-dialog__footer {
    text-align: right;
  }
  .add_invoice_messagebt {
    padding-bottom: 15px;
  }
  .text_overflow {
    width: 85px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;
    cursor: pointer;
  }
  ::v-deep .detail_widthPay1000 .el-dialog {
    margin-top: 7vh !important;
  }
  ::v-deep .bg-purple-light3 .el-table {
    margin-bottom: 20px;
    height: auto;
    overflow-y: scroll;
  }
  ::v-deep .bg-purple-light3 {
    overflow-y: scroll;
  }
  ::v-deep .detail_widthPay1000 .el-dialog--center .el-dialog__body {
    max-height: inherit !important;
  }
  ::v-deep .exceptArrivalClass .el-date-editor--date:hover .el-input__prefix {
    display: none;
  }
  ::v-deep .exceptRepaymentClass .el-date-editor--date:hover .el-input__prefix {
    display: none;
  }
  .payTime >>> .el-date-editor.el-input {
    width: 380px;
  }
  /* ::v-deep.payForm.el-form-item {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  padding-top: 15px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                } */
  ::v-deep.payForm1 .el-select {
    /* margin-left: 15px; */
    width: 372px;
  }
  ::v-deep.payForm1 .el-upload__tip {
    display: contents;
    width: 150px;
    margin-left: 560px;
  }
  .bg-purple-light3 {
    border-radius: 10px;
    margin-left: 0px;
    width: 510px;
    height: 632px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    background: #f7fafc;
  }
  .bg-purple-light4 {
    border-radius: 10px;
    margin-left: 0px;
    width: 510px;
    height: 632px;
    padding-top: 20px;
    background: #f7fafc;
  }
  ::v-deep .inner_common .el-form-item__label {
    width: 100px;
  }
  ::v-deep.detail_widthPay1000 .el-dialog {
    width: 1100px;
    height: 796px;
  }
  ::v-deep.detail_widthPay1000 .el-form-item__content {
    display: flex;
  }
  ::v-deep.detail_widthPay1000 .el-form-item {
    /* padding-top: 15px; */
    margin-left: 20px;
    margin-right: 20px;
  }

  ::v-deep .detail_widthPay1000 .margin_delete {
    margin-left: 0;
    margin-right: 0;
  }
  ::v-deep.detail_widthPay1000 .el-dialog--center .el-dialog__footer {
    margin-top: 15px;
    text-align: right;
  }
  ::v-deep.detail_width1000 .el-dialog {
    width: 1000px;
    height: 605px;
  }
  ::v-deep.detail_width1000 .el-form-item__content {
    display: flex;
  }
  ::v-deep.detail_width1000 .el-form-item {
    margin-left: 20px;
    margin-right: 20px;
  }
  ::v-deep.detail_width1000 .el-dialog--center .el-dialog__footer {
    margin-top: 13px;
    text-align: right;
  }
  .bg-purple-light {
    border-radius: 10px;
    margin-left: -17px;
    width: 470px;
    height: 450px;
    background: #f7fafc;
  }
  .bg-purple-light2 {
    border-radius: 10px;
    margin-left: 0px;
    padding-right: 13px;
    width: 462px;
    height: 450px;
    background: #f7fafc;
  }

  .form_item1 {
    /* margin-left: 40px; */
    margin-right: 20px;
    padding-top: 20px;
    /* padding-left: 20px; */
  }
  .form_item1-1 {
    /* margin-left: 40px; */
    margin-right: 20px;
    padding-top: 20px;
    /* padding-left: 20px; */
  }
  .form_item1-2 {
    /* margin-left: 40px; */
    margin-right: 20px;
    /* padding-top: 20px; */
    /* padding-left: 20px; */
  }
  ::v-deep.form_item1-1 .el-input__inner {
    display: inline-block;
    width: 325px;
    margin-left: 12px;
  }
  ::v-deep.form_item1-2 .el-input__inner {
    display: inline-block;
    width: 325px;
    margin-left: 13px;
  }
  ::v-deep.form_item1-3 .el-form-item__content {
    display: inline-block;
    margin-left: 15px;
  }
  ::v-deep.upload-demo li {
    display: none;
  }
  .invoiceUrlClass {
    margin-left: 118px;
    margin-top: -10px;
  }
  .add_invoice_messages li {
    float: left;
    width: 60px;
    height: 60px;
    margin-right: 12px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }

  .add_invoice_messages ul {
    margin-top: 10px;
  }
  .close_icon {
    position: absolute;
    top: -6px;
    left: 52px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fb6476;
  }
  .close_icon i {
    position: relative;
    top: -3px;
    left: 2px;
    font-size: 8px;
    color: #ffffff;
  }
  .add_invoice_messages >>> .el-input__prefix {
    left: 92%;
  }
  ::v-deep .el-upload-list {
    width: 35px;
  }
  ::v-deep .el-upload__tip {
    right: 50px;
  }
  .change_padding .is-success .el-date-editor--date:hover >>> .el-input__prefix {
    display: none;
  }
  .search_flex_box >>> .el-date-editor .el-range-separator {
    display: none;
  }
  ::v-deep .el-badge {
    color: #fb6476;
  }
  ::v-deep .el-badge__content.is-fixed {
    top: 6px;
  }
  ::v-deep .el-badge__content.is-fixed.is-dot {
    right: -1px;
  }
  ::v-deep .el-badge__content {
    background-color: #fb6476;
  }
  .table_oper .item_color_money_complete {
    color: #e1e7ea;
    cursor: not-allowed;
  }
  .apply_box_top {
    display: flex;
    padding-top: 10px;
    margin-bottom: 24px;
  }
  .apply_box_top_left {
    width: 340px;
    height: 176px;
    margin-right: 60px;
    padding: 20px;
    background: #339c9b;
    box-shadow: 0px 10px 20px 0px rgba(47, 160, 159, 0.23);
    border-radius: 14px;
  }
  .apply_box_total {
    display: flex;
    align-items: center;
    width: 300px;
    height: 136px;
    padding-top: 20px;
    padding-bottom: 24px;
    padding-left: 16px;
    background: rgba(237, 242, 245, 0.2);
    box-shadow: 0px 4px 8px 0px rgba(0, 102, 101, 0.23);
    border-radius: 14px;
  }
  .apply_box_total i {
    flex: 1;
    margin-right: 30px;
    color: #ffffff;
    font-size: 34px;
  }
  .apply_box_total_text span:first-child {
    display: block;
    width: auto;
    height: 20px;
    line-height: 20px;
    margin-bottom: 16px;
    font-size: 20px;
    color: #e1e7ea;
  }
  .apply_box_total_text span:last-child {
    display: block;
    width: 200px;
    height: 50px;
    line-height: 50px;
    font-size: 38px;
    font-weight: bold;
    color: #ffffff;
  }
  .apply_box_top_right {
    flex: 1;
  }
  .echarts_box {
    height: 250px;
    margin-top: -40px;
  }
  .search_flex_box >>> .el-form-item {
    margin-bottom: 20px;
  }
  .search_flex_box >>> .el-form-item__content {
    float: left;
    width: 300px;
  }
  .search_flex_box >>> .el-input__inner {
    height: 30px;
  }
  .search_flex_box >>> .el-form-item:last-child {
    margin-bottom: 0;
  }
  .search_flex_box >>> .el-form-item:nth-child(3) {
    margin-right: 0;
  }
  .search_flex_box >>> .el-date-editor--daterange.el-input__inner {
    width: 300px;
  }
  .search_flex_box >>> .el-date-editor .el-range-separator {
    display: none;
  }
  .search_flex_box
    >>> .el-select
    .el-input.is-focus
    .el-input.is-active
    .el-input__inner {
    border: 1px solid #ffffff;
  }
  .search_condition {
    flex: 1;
  }
  .table_box_top {
    margin-bottom: 14px;
  }
  .table_box_top_title {
    flex: 1;
  }
  .dialog_width500 >>> .el-form-item__label {
    min-width: 100px !important;
  }
  .upload_box {
    width: 60px;
    height: 60px;
    margin-right: 6px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  .upload_box i {
    font-size: 20px;
    line-height: 60px;
    color: #7c969b;
  }
  .upload_box i:focus {
    color: #7c969b;
  }
  ::v-deep .el-range-editor.is-disabled .el-range__icon {
    position: absolute;
    left: 170px;
    line-height: 40px;
    color: #92a2bc;
  }
  .adds >>> .el-upload {
    display: none;
  }
  .voucher_position {
    position: relative;
  }
  .add_invoice_message li {
    float: left;
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
    margin-right: 12px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
    cursor: pointer;
  }
  .add_invoice_message li:nth-child(4n + 4) {
    margin-right: 0;
  }
  .be_overdue {
    color: #fb6476;
  }
  .change_flex >>> .el-upload-list {
    width: 350px;
  }
  .change_flex >>> .el-upload__tip {
    right: 79px;
  }
  @media screen and (max-width: 1664px) {
    ::v-deep.dialog_width500 .el-dialog {
      width: 500px;
    }
    ::v-deep.dialog_width590 .el-dialog {
      width: 400px;
    }
    ::v-deep .dialog_width590 .el-dialog--center .el-dialog__body {
      padding-left: 10px;
      padding-right: 10px;
    }
    ::v-deep.dialog_width590 .el-form-item {
      /* padding-top: 15px; */
      margin-left: 20px;
      margin-right: 20px;
    }
    ::v-deep .dialog_width590 .el-dialog__footer {
      padding-bottom: 20px;
      text-align: center !important;
    }
    ::v-deep .dialog_width590 .margin_delete {
      margin-left: 0;
      margin-right: 0;
    }
    ::v-deep.dialog_width590 .el-dialog--center .el-dialog__footer {
      text-align: right;
    }
    ::v-deep .detail_widthPay1000 .el-dialog {
      margin-top: 15vh !important;
    }
    ::v-deep .inner_common .el-form-item__label {
      width: 88px;
    }
    ::v-deep .detail_widthPay1000 .margin_delete {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    ::v-deep .bg-purple-light3 .el-table {
      /* height: 472px; */
      margin-bottom: 13px;
    }
    .payTime >>> .el-date-editor.el-input {
      width: 380px;
    }
    /* ::v-deep.payForm.el-form-item {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    padding-top: 15px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  } */
    ::v-deep.payForm1 .el-select {
      /* margin-left: 15px; */
      width: 340px;
    }
    ::v-deep.payForm1 .el-upload__tip {
      font-size: 13px;
      display: flex;
      /* width: 0px; */
      margin-left: 10px;
    }
    .bg-purple-light3 {
      border-radius: 10px;
      margin-left: 0px;
      width: 370px;
      height: 421px;
      padding-top: 13px;
      padding-left: 13px;
      padding-right: 13px;
      background: #f7fafc;
    }
    .bg-purple-light4 {
      border-radius: 10px;
      margin-left: 2px;
      width: 370px;
      height: 421px;
      padding-top: 13px;
      background: #f7fafc;
    }
    ::v-deep .detail_widthPay1000 .el-dialog--center .el-dialog__body {
      padding-left: 10px;
    }
    ::v-deep.detail_widthPay1000 .el-dialog {
      width: 800px;
      height: 550px;
    }
    ::v-deep.detail_widthPay1000 .el-form-item__content {
      display: flex;
    }
    ::v-deep.detail_widthPay1000 .el-form-item {
      /* padding-top: 15px; */
      margin-left: 20px;
      margin-right: 20px;
    }
    ::v-deep.detail_widthPay1000 .el-dialog--center .el-dialog__footer {
      margin-top: 15px;
      text-align: right;
    }

    ::v-deep.detail_width1000 .el-dialog {
      width: 790px;
      height: 490px;
    }
    ::v-deep.detail_width1000 .el-form-item__content {
      display: flex;
    }
    ::v-deep.detail_width1000 .el-form-item {
      margin-left: 20px;
      margin-right: 20px;
    }
    ::v-deep.detail_width1000 .el-dialog--center .el-dialog__footer {
      margin-top: 15px;
      text-align: right;
    }
    .bg-purple-light {
      border-radius: 10px;
      margin-left: 0px;
      width: 360px;
      height: 350px;
      background: #f7fafc;
    }
    .bg-purple-light2 {
      border-radius: 10px;
      margin-left: 0px;
      width: 360px;
      height: 350px;
      background: #f7fafc;
    }
    .form_item1 {
      /* margin-left: 40px; */
      margin-right: 20px;
      padding-top: 20px;
      /* padding-left: 20px; */
    }
    .form_item1-1 {
      /* margin-left: 40px; */
      margin-right: 20px;
      padding-top: 20px;
      /* padding-left: 20px; */
    }
    .form_item1-2 {
      /* margin-left: 40px; */
      margin-right: 20px;
      /* padding-top: 20px; */
      /* padding-left: 20px; */
    }
    ::v-deep.form_item1-1 .el-input__inner {
      display: inline-block;
      width: 246px;
      margin-left: 12px;
    }
    ::v-deep.form_item1-2 .el-input__inner {
      display: inline-block;
      width: 246px;
      margin-left: 12px;
    }
    /* ::v-deep.form_item1-3 .el-input__inner{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                display: flex;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                width: 220px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                margin-left: 13px;   
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            } */
    ::v-deep.form_item1-3 .el-form-item__content {
      display: inline-block;
      margin-left: 15px;
    }
    .invoiceUrlClass {
      margin-left: 107px;
      margin-top: -14px;
    }
    .add_invoice_messages li {
      float: left;
      width: 40px;
      height: 40px;
      margin-right: 8px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }
    .add_invoice_messages ul {
      margin-top: 6px;
    }
    .close_icon {
      position: absolute;
      top: -6px;
      left: 32px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #fb6476;
      transform: scale(0.8);
    }
    .close_icon i {
      position: relative;
      top: -3px;
      left: 2px;
      font-size: 8px;
      color: #ffffff;
    }
    ::v-deep .el-upload-list {
      width: 23px;
    }
    ::v-deep .el-upload__tip {
      right: 33px;
    }
    ::v-deep .el-badge__content.is-fixed {
      top: 4px;
    }
    ::v-deep .el-badge__content.is-fixed.is-dot {
      right: -1px;
    }
    .apply_box_top {
      display: flex;
      padding-top: 6px;
      margin-bottom: 16px;
    }
    .apply_box_top_left {
      width: 340px;
      height: 176px;
      margin-right: 60px;
      padding: 20px;
      background: #339c9b;
      box-shadow: 0px 10px 20px 0px rgba(47, 160, 159, 0.23);
      border-radius: 14px;
    }
    .apply_box_top_left {
      width: 226px;
      height: 117px;
      margin-right: 40px;
      padding: 13px;
      background: #339c9b;
      box-shadow: 0px 6px 13px 0px rgba(47, 160, 159, 0.23);
      border-radius: 10px;
    }
    .apply_box_total {
      display: flex;
      align-items: center;
      width: 200px;
      height: 90px;
      padding-top: 13px;
      padding-bottom: 10px;
      padding-left: 10px;
      padding-right: 13px;
      background: rgba(237, 242, 245, 0.2);
      box-shadow: 0px 4px 8px 0px rgba(0, 102, 101, 0.23);
      border-radius: 10px;
    }
    .apply_box_total i {
      flex: 1;
      margin-right: 20px;
      color: #ffffff;
      font-size: 22px;
    }
    .apply_box_total_text span:first-child {
      display: block;
      width: auto;
      height: 14px;
      line-height: 14px;
      margin-bottom: 10px;
      font-size: 14px;
      color: #e1e7ea;
    }
    .apply_box_total_text span:last-child {
      display: block;
      width: 133px;
      height: 33px;
      line-height: 33px;
      font-size: 26px;
      font-weight: bold;
      color: #ffffff;
    }
    .echarts_box {
      height: 166px;
      margin-top: -26px;
    }
    .search_flex_box >>> .el-form-item {
      margin-bottom: 13px;
    }
    .search_flex_box >>> .el-form-item__content {
      float: left;
      width: 200px;
    }
    .search_flex_box >>> .el-input__inner {
      height: 20px;
    }
    .search_flex_box >>> .el-date-editor--daterange.el-input__inner {
      width: 200px;
    }
    .table_box_top {
      margin-bottom: 9px;
    }
    .dialog_width500 >>> .el-form-item__label {
      min-width: 83px !important;
    }
    .upload_box {
      width: 40px;
      height: 40px;
      margin-right: 6px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }
    .upload_box i {
      font-size: 14px;
      line-height: 40px;
      color: #7c969b;
    }
    ::v-deep .el-range-editor.is-disabled .el-range__icon {
      position: absolute;
      left: 113px;
      line-height: 26px;
      color: #92a2bc;
    }
    .add_invoice_message li {
      float: left;
      width: 40px;
      height: 40px;
      margin-bottom: 6px;
      margin-right: 8px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
      cursor: pointer;
    }
    .change_flex >>> .el-upload-list {
      width: 216px;
    }
    .change_flex >>> .el-upload__tip {
      left: 10px;
      right: 0px;
      width: 229px;
      top: 5px;
    }
  }
</style>